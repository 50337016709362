<template>
  <div>
    <div class="bg max-w1200 mrauto mt47 mb51 pd20">
      <div class="pd40 bg-ffffff bor-E6E6E6">
        <div>
          <div>
            <el-form ref="form" :model="form" label-width="7.5rem" label-position="right">
              <el-form-item label="店铺类型：">
                <el-select
                  v-model="form.doortype"
                  placeholder="选择店铺类型"
                  @change="doorTypechange"
                >
                  <el-option
                    v-for="(item, index) in doortypelist"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="用餐方式：" v-if="form.doortype == 1">
                <el-select v-model="form.dinnertype" placeholder="选择用餐方式">
                  <el-option
                    v-for="(item, index) in dinnertypeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="类别：">
                <el-checkbox-group
                  v-model="form.classtype"
                  @change="handleCheckedCitiesChange"
                >
                  <el-checkbox
                    :label="item.id"
                    name="form.label"
                    v-for="(item, index) in classtypeList"
                    :key="index"
                  >
                    {{ item.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="店铺名称：">
                <el-input v-model="form.doorname" placeholder="请输入店铺名称"></el-input>
              </el-form-item>
              <el-form-item label="所在地区：">
                <el-cascader
                  v-model="form.citytext"
                  :options="cityjson"
                  :props="{
                    expandTrigger: 'hover',
                    value: 'label',
                  }"
                  @change="handleChange"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="详细地址：">
                <el-input v-model="form.address" placeholder="请输入详细地址"></el-input>
              </el-form-item>
              <el-form-item label="联系人：">
                <el-input v-model="form.name" placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系电话：">
                <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
              </el-form-item>
              <el-form-item label="上传证件：">
                <el-upload
                  class="avatar-uploader"
                  action="https://pt.baipubang.com/api/other/upload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  name="media"
                  :before-upload="beforeUpload"
                >
                  <img v-if="form.imgUrl" :src="form.imgUrl" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="营业执照：">
                <el-upload
                  class="avatar-uploader"
                  action="https://pt.baipubang.com/api/other/upload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess2"
                  name="media"
                  :before-upload="beforeUpload2"
                >
                  <img v-if="form.imgUrl2" :src="form.imgUrl2" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="店铺封面：">
                <el-upload
                  class="avatar-uploader"
                  action="https://pt.baipubang.com/api/other/upload"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess3"
                  name="media"
                  :before-upload="beforeUpload3"
                >
                  <img v-if="form.imgUrl3" :src="form.imgUrl3" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="sbmitInfo()"
                  >提交审核</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPlatformClass,
  merchants_apply_sub,
  edit_merchants_apply_view,
  edit_merchants_apply_sub,
} from "../../utils/api.js";
import cityjson from "../../assets/js/country-level3-data";
export default {
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        if (this.$route.query.apply_id) {
          this.apply_id = this.$route.query.apply_id;
          this.getInfo();
        }
      },
    },
  },

  data() {
    return {
      form: {
        doortype: "", //店铺类型
        dinnertype: "", //用餐方式
        classtype: [], //类别
        doorname: "", //店铺名称
        citytext: "", //城市
        province: "", //省
        city: "", //市
        city_area: "", //区
        address: "", //详细地址
        name: "", //详细地址
        phone: "", //详细地址
        imgUrl: "", //证件-绝对
        path: "", //-相对
        imgUrl2: "", //营业执照-绝对
        path2: "", //-相对
        imgUrl3: "", //店铺封面-绝对
        path3: "", //-相对
      },
      cityjson: cityjson,
      classtypeList: "",
      dinnertypeList: [
        {
          value: 1,
          label: "仅外卖",
        },
        {
          value: 2,
          label: "仅堂食",
        },
        {
          value: 3,
          label: "均可",
        },
      ],
      doortypelist: [
        {
          value: 1,
          label: "美食",
        },
        {
          value: 2,
          label: "丽人",
        },
        {
          value: 3,
          label: "休闲",
        },
        {
          value: 4,
          label: "住宿",
        },
        {
          value: 5,
          label: "教培",
        },
        {
          value: 6,
          label: "车养",
        },
      ],
      gridData: [],
      dialogTableVisible: false,
      list: "",
      type: "",
      navIndex: "1",
      Info: "",
      parent_id: "",
      partner_val: "",
      uid: "",
      apply_id: "",
    };
  },
  mounted() {
    let uid = window.localStorage.getItem("uid");
    if (uid) {
      this.uid = uid;
      if (this.$route.query.apply_id) {
        this.apply_id = this.$route.query.apply_id;
        this.getInfo();
      }
    } else {
      this.$message.error("还未登录，请登录后再使用");
      setTimeout(() => {
        this.$router.push({
          path: "/login",
          query: {navid: 10,},
        });
        // let routeData = this.$router.resolve({
        //   path: "/login",
        //   query: { navid: 10 },
        // });
        // window.open(routeData.href, "_blank");
      }, 1500);
    }
  },
  methods: {
    getInfo() {
      let that = this;
      edit_merchants_apply_view({
        apply_id: that.apply_id,
      })
        .then((res) => {
          console.log(res);
          that.form.doortype = res.data.val.store_type;
          that.form.dinnertype = res.data.val.store_type;
          that.form.doorname = res.data.val.store_name;
          let add = [];
          add.push(res.data.val.province);
          add.push(res.data.val.city);
          add.push(res.data.val.city_area);
          that.form.citytext = add;
          that.form.province = res.data.val.province;
          that.form.city = res.data.val.city;
          that.form.city_area = res.data.val.city_area;
          that.form.address = res.data.val.address;
          that.form.name = res.data.val.name;
          that.form.phone = res.data.val.phone;
          that.form.path = res.data.val.card_path;
          that.form.imgUrl = res.data.val.card_img;
          that.form.path2 = res.data.val.business_path;
          that.form.imgUrl2 = res.data.val.business_img;
          that.form.path3 = res.data.val.logo_path;
          that.form.imgUrl3 = res.data.val.logo;
          that.getclass();
          setTimeout(() => {
            let arr = [];
            res.data.val.platform_id.forEach((item) => {
              arr.push(Number(item.value));
            });
            that.form.classtype = arr;
            console.log(that.form.classtype);
          }, 3000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCheckedCitiesChange(value) {
      console.log(value);
      this.form.classtype = value;
      console.log(this.form.classtype);
    },
    // 申请商家入职提交审核
    sbmitInfo() {
      let that = this;
      let platformId = "";
      if (that.form.classtype.length && that.form.classtype) {
        platformId = that.form.classtype.join(",");
      }
      if (that.apply_id) {
        edit_merchants_apply_sub({
          id: that.apply_id,
          store_type: that.form.doortype,
          store_name: that.form.doorname,
          province: that.form.province, //四川省
          city: that.form.city, //城市
          city_area: that.form.city_area, //区县
          address: that.form.address, //详细地址
          name: that.form.name,
          phone: that.form.phone,
          card_img: that.form.path, //证件照片
          business_img: that.form.path2, //营业执照
          logo: that.form.path3, //门店logo
          platform_id: platformId,
          selling_type: that.form.dinnertype,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success(res.msg);
            setTimeout(() => {
              // this.$router.push({
              //   path: "/indexer",
              //   query: { navid: 1 },
              // });
              let routeData = this.$router.resolve({
                path: "/indexer",
                query: { navid: 1 },
              });
              window.open(routeData.href, "_blank");
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.msg);
          });
      } else {
        merchants_apply_sub({
          uid: that.uid,
          store_type: that.form.doortype,
          store_name: that.form.doorname,
          province: that.form.province, //四川省
          city: that.form.city, //城市
          city_area: that.form.city_area, //区县
          address: that.form.address, //详细地址
          name: that.form.name,
          phone: that.form.phone,
          card_img: that.form.path, //证件照片
          business_img: that.form.path2, //营业执照
          logo: that.form.path3, //门店logo
          platform_id: platformId,
          selling_type: that.form.dinnertype,
        })
          .then((res) => {
            console.log(res);
            if (res.code != 200) {
              this.$message.error(res.msg);
              return;
            }
            this.$message.success(res.msg);
            setTimeout(() => {
              // this.$router.push({
              //   path: "/indexer",
              //   query: { navid: 1 },
              // });
              let routeData = this.$router.resolve({
                path: "/indexer",
                query: { navid: 1 },
              });
              window.open(routeData.href, "_blank");
            }, 1500);
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.msg);
          });
      }
    },
    // 证件
    beforeUpload(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/png";

      if (!isJPG && !isJPG2) {
        this.$message.error("只能上传图片!");
      }
      return isJPG || isJPG2;
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file);
      this.form.path = res.data.media;
      this.form.imgUrl = res.data.path_media;
    },
    // 营业执照
    beforeUpload2(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/png";

      if (!isJPG && !isJPG2) {
        this.$message.error("只能上传图片!");
      }
      return isJPG || isJPG2;
    },
    handleAvatarSuccess2(res, file) {
      console.log(res, file);
      this.form.path2 = res.data.media;
      this.form.imgUrl2 = res.data.path_media;
    },
    // 店铺封面
    beforeUpload3(file) {
      console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isJPG2 = file.type === "image/png";

      if (!isJPG && !isJPG2) {
        this.$message.error("只能上传图片!");
      }
      return isJPG || isJPG2;
    },
    handleAvatarSuccess3(res, file) {
      console.log(res, file);
      this.form.path3 = res.data.media;
      this.form.imgUrl3 = res.data.path_media;
    },

    // 城市选择
    handleChange(value) {
      console.log(value);
      this.form.province = value[0];
      this.form.city = value[1];
      this.form.city_area = value[2];
    },
    // 店铺类型切换
    doorTypechange(e) {
      console.log(e);
      this.form.doortype = e;
      this.getclass();
    },
    // 获取类型
    getclass() {
      let that = this;
      getPlatformClass({
        parent_id: that.form.doortype,
      })
        .then((res) => {
          console.log(res);
          that.classtypeList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
// .bg {
//   background-image: url(../../assets/img/bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
// }

.el-input.is-disabled .el-input__inner {
  background-color: #ffffff !important;
  border-color: #d2d2d2 !important;
  color: #222222 !important;
  font-size: 0.875rem !important;
  cursor: not-allowed;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 0.375rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 1.75rem;
  color: #8c939d;
  width: 11.125rem;
  height: 11.125rem;
  line-height: 11.125rem;
  text-align: center;
}

.avatar {
  width: 11.125rem;
  height: 11.125rem;
  display: block;
}
</style>
